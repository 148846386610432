import { Link } from "react-router-dom";
import Error from "../../img/Error.png";
import "./NotFound.css";

export function NotFound() {
    return(
        <main className="full-size">
            <img src={Error} alt="" />
            <h1>Stay calm and don't freak out!</h1>
            <h2>404 Error!</h2>
            <Link to={"/"}>Home</Link>
        </main>
    )
}