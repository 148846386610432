import "./Loading.css";

export function Loading() {
    return(
        <main className="full-size">
            <div className="loader">
                <div className="inner one"></div>
                <div className="inner two"></div>
                <div className="inner three"></div>
            </div>
        </main>
    );
}