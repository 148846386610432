import { useTranslation } from "react-i18next";
import "./Home.css";

export function Home() {

    const[t] = useTranslation("global");
    
    return(
        <>
            <div className="title">
                <span></span>
                <h1>{t("home.title")}</h1>
            </div>

            <div className="display-text">
                <p>{t("home.greetings")}</p>
                <p>{t("home.intro")}</p>
                <p>{t("home.text")}</p>
            </div>

            <h1 className="signature">Simon A.</h1>
        </>
    )
}