import { useTranslation } from "react-i18next";
import "./Skills.css";
import { motion } from "framer-motion"
import Data from "./Data.json";

export function Skills() {

    const[t] = useTranslation("global");

    function compareByDate(item1, item2) {
        return item1.date - item2.date;
    }

    return(
        <>
            <div className="title">
                <span></span>
                <h1>{t("skills.title")}</h1>
            </div>

            <p className="display-text">{t("skills.text")}</p>


            {Data.Skills.map((skill) => (
                <>
                    <h3>{skill.Title}</h3>
                    <div className="grid-cont">
        
                        {skill.Tabs.sort(compareByDate).map((tab) => (
                            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} viewport={{ once: true }} transition={{ duration: 2 }}>
                            <div className="tab">
                                <img src={tab.photo} alt="" />
                                <h5>{tab.title}</h5>
                                <h5>{new Date().getFullYear() - tab.date} years</h5>
                            </div>
                            </motion.div>
                        ))}
        
                    </div>
                </>
            ))}
        </>
    )
}