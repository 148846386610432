import { useTranslation } from "react-i18next";
import CV_CZ from "../../files/Simon_Adam_CV_CZ.pdf";
import CV_EN from "../../files/Simon_Adam_CV_EN.pdf";
import "./Aside.css"

export function Aside() {
    const[t, i18n] = useTranslation("global");

    return(
        <aside>
            <div className="my-pic display-anim">
                <h1>Simon Adam</h1>
                <h2>Full-stack developer</h2>
            </div>

            <ul>
                <li>
                    <h4>{t("aside.row1.title")}</h4>
                    <h4>{t("aside.row1.answer")}</h4>
                </li>
                <li>
                    <h4>{t("aside.row2.title")}</h4>
                    <h4>{t("aside.row2.answer")}</h4>
                </li>
                <li>
                    <h4>{t("aside.row3.title")}</h4>
                    <h4>{new Date().getFullYear() - t("aside.row3.answer")}</h4>
                </li>
                <li>
                    <h4>{t("aside.row4.title")}</h4>
                    <h4>{t("aside.row4.answer")}</h4>
                </li>
            </ul>

            <div className="center">
                <a href={i18n.language === "en" ? CV_EN : CV_CZ} download className="download">{t("aside.download")} CV <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="#c7c7c7" viewBox="0 -960 960 960"><path d="M480-332 232-580l89-89 96 97v-262h126v262l96-97 89 89-248 248ZM252-126q-53 0-89.5-36.5T126-252v-120h126v120h456v-120h126v120q0 53-36.5 89.5T708-126H252Z"/></svg></a>
            </div>
        </aside>
    )
}