import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Home } from './pages/home/Home';
import { Body } from './comp/body/Body';
import { Contact } from './pages/contact/Contact';
import { Exper } from './pages/exper/Exper';
import { Skills } from './pages/skills/Skills';
import { useEffect, useState } from 'react';
import { Loading } from './comp/load/Loading';
import { NotFound } from './comp/error/NotFound';

function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Body>
          <Home />
        </Body>
      )
    },
    {
      path: "experience",
      element: (
        <Body>
          <Exper />
        </Body>
      )
    },
    {
      path: "contact",
      element: (
        <Body>
          <Contact />
        </Body>
      )
    },
    {
      path: "skills",
      element: (
        <Body>
          <Skills />
        </Body>
      )
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  if(isLoading) {
    return(
      <Loading />
    )
  }

  return (
    <RouterProvider router={router} />
  );
}

export default App;
