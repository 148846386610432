import { Link } from "react-router-dom";
import { Aside } from "../aside/Aside";
import "./Body.css";
import { useTranslation } from "react-i18next";

import CZE from "../../img/flag/cze.png";
import USA from "../../img/flag/usa.png";
import { useState } from "react";

export function Body({ children }) {

    const[t, i18n] = useTranslation("global");
    const[lang, setLang] = useState(i18n.language === "en" ? true : false)
    const[isOpen, setIsOpen] = useState(false);

    const location = window.location.pathname

    const changeLanguage = () => {
        if(i18n.language === "en") {
            i18n.changeLanguage("cz")
            setLang(false);
        } else {
            i18n.changeLanguage("en")
            setLang(true);
        }
    }

    return(
        <>
            <main className="app">
                <Aside />

                <div className="container">

                    <header className="desktop-nav">
                        <nav>
                            <ul>
                                <li>
                                    <Link className={location === "/" ? "active" : ""} to="/">{t("nav.link1")}</Link>
                                </li>
                                <li>
                                    <Link className={location === "/experience" ? "active" : ""} to="/experience">{t("nav.link2")}</Link>
                                </li>
                                <li>
                                    <Link className={location === "/skills" ? "active" : ""} to="/skills">{t("nav.link3")}</Link>
                                </li>
                                <li>
                                    <Link className={location === "/contact" ? "active" : ""} to="/contact">{t("nav.link4")}</Link>
                                </li>
                            </ul>
                        </nav>

                        <button className="lang" onClick={() => changeLanguage()}>
                            <img src={lang ? CZE : USA} alt="" />
                        </button>
                    </header>

                    <nav className="for-mobile">
                        <button onClick={() => setIsOpen(!isOpen)}>
                            Menu
                            <svg className={isOpen ? "rotate" : ""} xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill="#FFF" viewBox="0 -960 960 960"><path d="M480-314 208-586l88-88 184 184 184-184 88 88-272 272Z"/></svg>
                        </button>
                        <ul className={isOpen ? "open" : "close"}>
                            <li>
                                <Link className={location === "/" ? "active" : ""} to="/">{t("nav.link1")}</Link>
                            </li>
                            <li>
                                <Link className={location === "/experience" ? "active" : ""} to="/experience">{t("nav.link2")}</Link>
                            </li>
                            <li>
                                <Link className={location === "/skills" ? "active" : ""} to="/skills">{t("nav.link3")}</Link>
                            </li>
                            <li>
                                <Link className={location === "/contact" ? "active" : ""} to="/contact">{t("nav.link4")}</Link>
                            </li>
                            <li>
                                <button className="m-lang" onClick={() => changeLanguage()}>
                                    <img src={lang ? CZE : USA} alt="" />
                                </button>
                            </li>
                        </ul>
                    </nav>

                    <section>
                        { children }
                    </section>

                    <footer>
                        <p>Made with love ❤️</p>
                        <p className="no-mobile">© 2023 All rights reserved</p>
                    </footer>

                </div>
            </main>
        </>
    )
}