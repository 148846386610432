import { useTranslation } from "react-i18next";
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import "./Exper.css";

export function Exper() {

    const[t] = useTranslation("global");

    return(
        <>
            <div className="title">
                <span></span>
                <h1>{t("exper.title")}</h1>
            </div>

            <p className="display-text">{t("exper.text")}</p>

            <div className="timeline">

                <h3>{t("exper.school")}</h3>

                <Timeline sx={{ [`& .${timelineItemClasses.root}:before`]: { flex: 0, padding: 0 }, }} >
                    <TimelineItem>
                        <TimelineSeparator>
                        <TimelineDot variant="outlined" color="primary" />
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent className="display-text">
                            <h4>{t("exper.sssvt-name")}</h4>
                            <h5 className="time">2019 - 2023</h5>
                            <h5>{t("exper.sssvt-text")}</h5>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem className="last">
                        <TimelineSeparator>
                        <TimelineDot variant="outlined" color="primary" />
                        </TimelineSeparator>
                        <TimelineContent className="display-text">
                            <h4>{t("exper.czu-name")}</h4>
                            <h5 className="time">{t("exper.czu-date")}</h5>
                            <h5>{t("exper.czu-text")}</h5>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>



                <h3>{t("exper.work")}</h3>

                <Timeline sx={{ [`& .${timelineItemClasses.root}:before`]: { flex: 0, padding: 0 }, }} >
                    <TimelineItem>
                        <TimelineSeparator>
                        <TimelineDot variant="outlined" color="primary" />
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent className="display-text">
                            <h4>{t("exper.just-name")}</h4>
                            <h5 className="time">{t("exper.just-date")}</h5>
                            <h5>{t("exper.just-text")}</h5>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                        <TimelineDot variant="outlined" color="primary" />
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent className="display-text">
                            <h4>{t("exper.fort-name")}</h4>
                            <h5 className="time">{t("exper.fort-date")}</h5>
                            <h5>{t("exper.fort-text")}</h5>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem className="last">
                        <TimelineSeparator>
                        <TimelineDot variant="outlined" color="primary" />
                        </TimelineSeparator>
                        <TimelineContent className="display-text">
                            <h4>{t("exper.elf-name")}</h4>
                            <h5 className="time">{t("exper.elf-date")}</h5>
                            <h5>{t("exper.elf-text")}</h5>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </div>
        </>
    )
}