import ReactDOM from 'react-dom/client';
import React from 'react';
import App from './App';
import './index.css';

import global_cze from "./language/cze.json";
import global_eng from "./language/eng.json";

import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

i18next.init({
  interpolation: {escapeValues: false},
  lng: "en",
  resources: {
    en: {
      global: global_eng
    },
    cz: {
      global: global_cze
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);